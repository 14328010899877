<template>
  <div class="page-wrapper gray data-page">
    <div class="data-page-box">
      <div class="data-item pb-0 search-wrapper w-200">
        <div class="search-item w-280">
          <el-date-picker
            size="small"
            v-model="formData.date"
            :picker-options="pickerOptions"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
        <div class="search-item">
          <el-select v-model="formData.role" placeholder="请选择用户类型" size="small">
            <el-option v-for="item of role" :key="item.value" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <el-select v-model="formData.school_id" placeholder="请选择校区" size="small">
            <el-option v-for="item of school" :key="item.value" :label="item.school_name" :value="item.id"> </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <el-select v-model="formData.grade_id" placeholder="请选择年级" size="small">
            <el-option v-for="item of grade" :key="item.value" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </div>
        <div class="search-item auto">
          <el-button type="primary" size="small" @click="handleSearch(0)">查找</el-button>
          <el-button size="small" @click="handleSearch(1)">重置</el-button>
        </div>
      </div>
      <div class="data-item" v-loading="loading">
        <el-row class="page-row no-margin">
          <el-col :md="12"><div class="data-item-title">WEB端用户使用情况</div></el-col>
          <el-col :md="12"><div class="data-item-title">学生管理详情页</div></el-col>
        </el-row>
        <div class="data-item-content">
          <el-row class="page-row no-margin">
            <el-col class="item" :md="6" v-for="(item, index) in list" :key="index">
              <div class="inner">
                <div class="label">{{ item.label }}</div>
                <div class="content">{{ item.value }}</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="data-item" v-loading="loading">
        <div class="data-item-title">德育模块</div>
        <div class="data-item-content">
          <el-table :data="moral_education" size="small">
            <el-table-column :prop="i.prop" :label="i.label" v-for="i in table_moral" :key="i.prop"></el-table-column>
          </el-table>
        </div>
      </div>
      <div class="data-item" v-loading="loading">
        <div class="data-item-title">常规业务模块</div>
        <div class="data-item-content">
          <el-table :data="regular_business" size="small">
            <el-table-column :prop="i.prop" :label="i.label" v-for="i in table_regular" :key="i.prop"></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { setDatePicker } from '@/utils'
import { getApi2 } from '@/api'
export default {
	_config:{"route":{"path":"detail","meta":{"title":"数据展示"}}},
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 3600 * 1000 * 24 * 1
        },
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const [start, end] = [setDatePicker({ num: 8 }), setDatePicker({ num: 1 })]
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const [start, end] = [setDatePicker({ num: 1, type: 'month', excludeToday: 1 }), setDatePicker({ num: 1 })]
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const [start, end] = [setDatePicker({ num: 3, type: 'month', excludeToday: 1 }), setDatePicker({ num: 1 })]
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      formData: {
        date: '',
        role: '',
        school_id: '',
        grade_id: ''
      },
      moral_education: [],
      regular_business: [],
      list: [],
      role: [],
      school: [],
      grade: [],
      loading: false,
      table_moral: [
        { prop: 'title', label: '' },
        { prop: 'start_psychology', label: '发起心理咨询' },
        { prop: 'accept_psychology', label: '接收心理咨询' },
        { prop: 'education', label: '德育一对一' }
      ],
      table_regular: [
        { prop: 'title', label: '' },
        { prop: 'teacher', label: '教师备忘录' },
        { prop: 'student', label: '学生备忘录' },
        { prop: 'message', label: '师生留言板' },
        { prop: 'student_coach', label: '学科一对一辅导' },
        { prop: 'teach_regular', label: '教务常规检查' }
      ]
    }
  },
  created() {
    this.getOptions()
    this.getDetail()
  },
  methods: {
    getParams() {
      let data = {}
      Object.keys(this.formData).forEach(key => {
        const val = this.formData[key]
        if (!val) return
        if (key !== 'date') return (data[key] = val)
        data['start_at'] = val[0]
        data['end_at'] = val[1]
      })
      return data
    },
    getDetail() {
      const data = this.getParams()
      this.loading = true
      getApi2('/tool/product/index', data)
        .then(res => {
          const { login_statistics, stu_man_statistics, moral_education, regular_business } = res
          this.moral_education = moral_education
          this.regular_business = regular_business
          this.list = [
            { label: '登录次数：', value: login_statistics.total },
            { label: '独立用户数：', value: login_statistics.user_total },
            { label: '独立用户数：', value: stu_man_statistics.user_total },
            { label: '使用次数：', value: stu_man_statistics.total },
          ]
        })
        .finally(() => {
          this.loading = false
        })
    },
    getOptions() {
      getApi2('/tool/product/param').then(res => {
        const { role, school, grade } = res
        school.unshift({ id: 0, school_name: '全部' })
        grade.unshift({ id: 0, name: '全部' })
        this.role = role
        this.school = school
        this.grade = grade
      })
    },
    handleSearch(type) {
      if (!this.formData.role && !type) {
        return this.$message.warning('请选择用户类型!')
      }
      if (type) {
        Object.keys(this.formData).forEach(key => {
          this.formData[key] = ''
        })
      }
      this.getDetail()
    }
  }
}
</script>

<style lang="scss" scoped>
.data {
  &-page {
    padding: 0;
    &-box {
      margin-right: 16rem;
    }
  }
  &-item {
    padding: 20rem;
    background-color: #fff;
    border-radius: 10rem;
    &:not(:last-child) {
      margin-bottom: 16rem;
    }
    &.pb-0 {
      padding-bottom: 0;
    }
    &-title {
      margin-bottom: 16rem;
      font-size: 16rem;
      color: #333;
      font-weight: 600;
    }
    &-content {
      padding: 20rem;
    }
  }
}
::v-deep {
  .el-date-editor,
  .el-select,
  .el-input {
    width: 100%;
  }
  .el-table__cell {
    background: #fff !important;
  }
  .el-table th.el-table__cell {
    color: #999;
    font-weight: 500;
  }
}
</style>
